<template>
  <AntCheckboxGroup
    v-model:value="value"
    :options="options"
    :class="[direction, { compact: layout === 'compact' }]"
    :disabled="formState?.disabled || disabled"
  />
</template>

<script setup lang="ts">
import { CheckboxGroup as AntCheckboxGroup } from 'ant-design-vue/es/checkbox'
import { computed } from 'vue'
import { useFormState } from './global-state'

const formState = useFormState()

interface Props {
  value: string[]
  options: { value: string; label: string; disabled?: boolean }[]
  direction?: 'horizontal' | 'vertical'
  disabled?: boolean
  layout?: 'normal' | 'compact'
}

const props = withDefaults(defineProps<Props>(), { direction: 'horizontal', layout: 'normal' })
const emit = defineEmits<{
  (e: 'update:value', val: string[]): void
}>()

const value = computed({
  get() {
    return props.value
  },
  set(val) {
    emit('update:value', val)
  }
})
</script>

<style lang="less" scoped>
.vertical {
  @apply flex flex-col gap-4;
}

.compact {
  @apply gap-1;
}
</style>
